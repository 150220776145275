define("sitemap/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xh6dsaxD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"login\"]]],null],[12],[2,\"Login\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "sitemap/templates/login.hbs"
    }
  });

  _exports.default = _default;
});