define("sitemap/components/container/uploads/file-field", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group form-inline">
    <input type="file" multiple="false" class="form-control" onchange={{@setSelectedFile}} />
    <button type="button" {{on "click" this.uploadFile}} class='btn btn-success'>Upload</button>
  </div>
  
  */
  {"id":"edzeyFG3","block":"{\"symbols\":[\"@setSelectedFile\"],\"statements\":[[10,\"div\"],[14,0,\"form-group form-inline\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,\"multiple\",\"false\"],[14,0,\"form-control\"],[15,\"onchange\",[32,1]],[14,4,\"file\"],[12],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-success\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"uploadFile\"]]],null],[12],[2,\"Upload\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}","meta":{"moduleName":"sitemap/components/container/uploads/file-field.hbs"}});

  let ContainerUploadsFileFieldComponent = (_dec = Ember._action, (_class = class ContainerUploadsFileFieldComponent extends _component.default {
    get selectedFile() {
      return this.args.selectedFile ?? null;
    }

    uploadFile() {
      if (this.selectedFile) {
        this.args.upload();
      } else {
        alert("Please select a file");
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype)), _class));
  _exports.default = ContainerUploadsFileFieldComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerUploadsFileFieldComponent);
});