define("sitemap/components/x-tree", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="sitemap-x-tree">
    {{#each @tree.nodes as |node|}}
      <XNode
              @tree={{@tree}}
              @node={{node}}
              @onInsert={{this.addContainerWithContext}}
              @onMove={{this.nodeMoved}} />
    {{/each}}
  </ul>
  */
  {"id":"R7oyoA8k","block":"{\"symbols\":[\"node\",\"@tree\"],\"statements\":[[10,\"ul\"],[14,0,\"sitemap-x-tree\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"nodes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"x-node\",[],[[\"@tree\",\"@node\",\"@onInsert\",\"@onMove\"],[[32,2],[32,1],[32,0,[\"addContainerWithContext\"]],[32,0,[\"nodeMoved\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}","meta":{"moduleName":"sitemap/components/x-tree.hbs"}});

  let XTreeComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class XTreeComponent extends _component.default {
    addContainerWithContext(node) {
      let parent;

      if (node) {
        parent = node.get('model');
      }

      this.args.addContainer(parent);
    }

    nodeMoved(a) {
      this.args.moveContainer(a);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addContainerWithContext", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addContainerWithContext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nodeMoved", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "nodeMoved"), _class.prototype)), _class));
  _exports.default = XTreeComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XTreeComponent);
});