define("sitemap/lib/titlecase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(str) {
    return str.underscore().split('_').map(Ember.String.capitalize).join(' ');
  }
});