define("sitemap/components/containers/_forms/article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @scope={{@model.container}} as |article|>
    <Container::Macros::XMetatags @scope={{article}} as |metaTags|>
      <Container::XField @key="contentType" @type="content-type-select" @scope={{metaTags}} />
    </Container::Macros::XMetatags>
  
    <Container::Macros::XHero @scope={{article}} as |hero|>
      <Container::Macros::XShowSectionNav @scope={{hero}} @default={{true}} />
    </Container::Macros::XHero>
  
    <fieldset>
      <legend>Article Content</legend>
      <Container::XFields @key="articleBlock" @scope={{article}} as |articleBlock|>
        <Container::XField @key="title" @type="string" @limit=50 @scope={{articleBlock}} />
        <Container::XField @key="content" @type="redactor" @limit=50 @scope={{articleBlock}} />
      </Container::XFields>
    </fieldset>
  
    <Container::Macros::XWidgetContact @scope={{article}} />
  
    <Container::Macros::XWidgetCallsToAction @scope={{article}} />
  
    <Container::Macros::XWidgetLinksLists @scope={{article}} />
  
    <Container::Macros::XResources @scope={{article}} />
  
    <Container::Macros::XTaggings @scope={{article}} />
  </Container::XFields>
  
  */
  {"id":"TYOALzq4","block":"{\"symbols\":[\"article\",\"articleBlock\",\"hero\",\"metaTags\",\"@model\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@scope\"],[[32,5,[\"container\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container/macros/x-metatags\",[],[[\"@scope\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"contentType\",\"content-type-select\",[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[4]}]]],[2,\"\\n\\n  \"],[8,\"container/macros/x-hero\",[],[[\"@scope\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/macros/x-show-section-nav\",[],[[\"@scope\",\"@default\"],[[32,3],true]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[10,\"fieldset\"],[12],[2,\"\\n    \"],[10,\"legend\"],[12],[2,\"Article Content\"],[13],[2,\"\\n    \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[\"articleBlock\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@limit\",\"@scope\"],[\"title\",\"string\",\"50\",[32,2]]],null],[2,\"\\n      \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@limit\",\"@scope\"],[\"content\",\"redactor\",\"50\",[32,2]]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"container/macros/x-widget-contact\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-widget-calls-to-action\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-widget-links-lists\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-resources\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-taggings\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/containers/_forms/article.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});