define("sitemap/routes/containers/index", ["exports", "sitemap/lib/authenticated-route", "sitemap/lib/sitemap/tree"], function (_exports, _authenticatedRoute, _tree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContainersIndexRoute extends _authenticatedRoute.default {
    model(_model, _transition) {
      return _tree.default.create(Ember.getOwner(this).ownerInjection());
    }

    afterModel(sitemap) {
      sitemap.load();
    }

  }

  _exports.default = ContainersIndexRoute;
});