define("sitemap/components/container/fields/x-number", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if @field.hasErrors "has-error"}}">
    <label class="control-label" for={{@field.uid}}>
      {{@field.label}}
    </label>
    <Input
            @type="number"
            class="form-control"
            id={{@field.uid}}
            @value={{@field.value}}
    />
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  
  
  */
  {"id":"JQiv1nsH","block":"{\"symbols\":[\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,1,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[32,1,[\"uid\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"input\",[[24,0,\"form-control\"],[16,1,[32,1,[\"uid\"]]]],[[\"@type\",\"@value\"],[\"number\",[32,1,[\"value\"]]]],null],[2,\"\\n\"],[6,[37,0],[[32,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,1,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-number.hbs"}});

  class ContainerFieldsXNumberComponent extends _component.default {}

  _exports.default = ContainerFieldsXNumberComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXNumberComponent);
});