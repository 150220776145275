define("sitemap/lib/transform-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function transformKeys(src, transform) {
    let type = Ember.typeOf(src);

    if (type === 'array') {
      return src.map(e => transformKeys(e, transform));
    } else if (type === 'instance' || type === 'object') {
      return Object.keys(src).reduce(function (obj, key) {
        obj[transform(key)] = transformKeys(Ember.get(src, key), transform);
        return obj;
      }, {});
    } else {
      return src;
    }
  }

  var _default = transformKeys;
  _exports.default = _default;
});