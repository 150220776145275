define("sitemap/components/container/fields/x-image-upload", ["exports", "@glimmer/component", "sitemap/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if this.hasErrors "has-error"}}">
    <label class="control-label" for={{@field.uid}}>
      {{@field.label}}
    </label>
  
    <div class='input-group'>
      <span class='input-group-addon'>URL</span>
      <Input
              @type="text"
              class="form-control"
              id={{@field.uid}}
              @value={{@field.value}}
      />
    </div>
  
    <Container::Uploads::FileField @selectedFile={{this.file}} @saving={{this.isSaving}} @setSelectedFile={{this.setSelectedFile}} @upload={{this.submit}} />
  
    {{#if this.isSaving}}
      <p class="help-block">Uploading&hellip;</p>
    {{/if}}
  
    {{#if this.uploadError}}
      <p class="help-block">{{this.uploadError}}</p>
    {{/if}}
  
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  
  */
  {"id":"mgPM2bsO","block":"{\"symbols\":[\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,0,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[32,1,[\"uid\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[2,\"URL\"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"],[16,1,[32,1,[\"uid\"]]]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"value\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"container/uploads/file-field\",[],[[\"@selectedFile\",\"@saving\",\"@setSelectedFile\",\"@upload\"],[[32,0,[\"file\"]],[32,0,[\"isSaving\"]],[32,0,[\"setSelectedFile\"]],[32,0,[\"submit\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isSaving\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"Uploading…\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"uploadError\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,0,[\"uploadError\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,1,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-image-upload.hbs"}});

  let ContainerFieldsXImageUploadComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember.computed.or('args.field.hasErrors', 'uploadError'), (_class = (_temp = class ContainerFieldsXImageUploadComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "imageUploadRequest", _descriptor, this);

      _initializerDefineProperty(this, "uploadError", _descriptor2, this);

      _initializerDefineProperty(this, "isSaving", _descriptor3, this);

      _initializerDefineProperty(this, "file", _descriptor4, this);

      _initializerDefineProperty(this, "hasErrors", _descriptor5, this);
    }

    submit() {
      let file = this.file;

      if (file) {
        this.processFile(file);
      }
    }

    setSelectedFile(event) {
      this.file = event.target.files[0];
    }

    async processFile(file) {
      let filename = file.name;
      let response = await this.imageUploadRequest.getSignedUrl(filename);
      let uploadData = await response.json();
      this.uploadError = null;
      this.isSaving = true;
      this.performUpload(uploadData).then(() => {
        const url = this.url;
        this.args.field.value = url;
        this.imageUploadRequest.saveUploadedImage(url, filename);
      }).catch(
      /* errorResponse */
      () => {
        this.uploadError = 'Invalid file';
      }).finally(() => {
        this.isSaving = false;
      });
    }

    performUpload(upload) {
      let file = this.file;
      let controller = this;
      let receivedResolver;
      let receivedRejector;
      this.url = upload.base_url;
      let receivedPromise = new Promise((resolve, reject) => {
        receivedResolver = resolve;
        receivedRejector = reject;
      });
      new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('PUT', upload.signed_url);
        xhr.setRequestHeader('X-CSRF-Token', _environment.default.CSRF_TOKEN);
        xhr.setRequestHeader('x-amz-acl', 'public-read');
        xhr.setRequestHeader('Content-Type', upload.mime);
        xhr.send(file);
        xhr.upload.addEventListener('progress', function (e) {
          Ember.run(function () {
            if (e.lengthComputable) {
              controller.progress = e.loaded / e.total * 100;
            }
          });
        });
        xhr.upload.addEventListener('load', function () {
          let http = this;
          Ember.run(function () {
            resolve(http);
          });
        });
        xhr.addEventListener('load', function () {
          let http = this;
          Ember.run(function () {
            if (http.status === 200) {
              receivedResolver(http);
            } else {
              receivedRejector(http);
            }
          });
        });
        xhr.upload.addEventListener('error', function () {
          let http = this;
          Ember.run(function () {
            reject(http);
          });
        });
      });
      return receivedPromise;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "imageUploadRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uploadError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "file", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedFile", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedFile"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasErrors", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContainerFieldsXImageUploadComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXImageUploadComponent);
});