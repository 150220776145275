define("sitemap/components/container/macros/x-principles", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Principles</legend>
    <Container::XFields @key="principles" @array={{true}} @min=0 @max=10 @scope={{@scope}} as |principles|>
      <Container::XField @key="principle" @type="number" @scope={{principles}} />
    </Container::XFields>
  </fieldset>
  
  
  */
  {"id":"9O6m2Dej","block":"{\"symbols\":[\"principles\",\"@scope\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Principles\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@min\",\"@max\",\"@scope\"],[\"principles\",true,\"0\",\"10\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"principle\",\"number\",[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-principles.hbs"}});

  class ContainerMacrosXPrinciplesComponent extends _component.default {}

  _exports.default = ContainerMacrosXPrinciplesComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXPrinciplesComponent);
});