define("sitemap/models/payload", ["exports", "sitemap/lib/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PayloadModel extends _model.default {}

  _exports.default = PayloadModel;
  PayloadModel.reopenClass({
    path: 'sitemap/api/payloads',
    type: 'payload',
    attributes: ['data']
  });
});