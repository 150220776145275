define("sitemap/components/container/macros/x-hero", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Hero</legend>
    <Container::XFields @key="hero" @scope={{@scope}} as |hero|>
      <h2>Carousel</h2>
      <Container::XFields @key="carousel" @array={{true}} @min=1 @scope={{hero}} as |carousel|>
        <h3>Image</h3>
        <Container::XField @key="image" @label="Background Image" @type="image-upload" @scope={{carousel}} />
        <Container::XFields @key="title" @scope={{carousel}} as |title|>
          <h3>Title</h3>
          <Container::XField @key="title1" @label="Line 1" @type="string-addon" @limit=50 @scope={{title}} />
          <Container::XField @key="title2" @label="Line 2" @type="string-addon" @limit=50 @scope={{title}} />
        </Container::XFields>
        <h3>Blurb</h3>
        <Container::XField @key="blurb" @type="string-addon" @label="Text" @scope={{carousel}} />
  
        <Container::Macros::XLink @key="link" @scope={{carousel}} />
      </Container::XFields>
  
      <Container::XField @key="theme" @type="hero-theme-select" @scope={{hero}} />
  
      {{yield hero}}
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"2hAtf3Rf","block":"{\"symbols\":[\"hero\",\"carousel\",\"title\",\"@scope\",\"&default\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Hero\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[\"hero\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Carousel\"],[13],[2,\"\\n    \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@min\",\"@scope\"],[\"carousel\",true,\"1\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Image\"],[13],[2,\"\\n      \"],[8,\"container/x-field\",[],[[\"@key\",\"@label\",\"@type\",\"@scope\"],[\"image\",\"Background Image\",\"image-upload\",[32,2]]],null],[2,\"\\n      \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[\"title\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"h3\"],[12],[2,\"Title\"],[13],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@label\",\"@type\",\"@limit\",\"@scope\"],[\"title1\",\"Line 1\",\"string-addon\",\"50\",[32,3]]],null],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@label\",\"@type\",\"@limit\",\"@scope\"],[\"title2\",\"Line 2\",\"string-addon\",\"50\",[32,3]]],null],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Blurb\"],[13],[2,\"\\n      \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@label\",\"@scope\"],[\"blurb\",\"string-addon\",\"Text\",[32,2]]],null],[2,\"\\n\\n      \"],[8,\"container/macros/x-link\",[],[[\"@key\",\"@scope\"],[\"link\",[32,2]]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"theme\",\"hero-theme-select\",[32,1]]],null],[2,\"\\n\\n    \"],[18,5,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-hero.hbs"}});

  class ContainerMacrosXHeroComponent extends _component.default {}

  _exports.default = ContainerMacrosXHeroComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXHeroComponent);
});