define("sitemap/services/model-request", ["exports", "sitemap/config/environment", "sitemap/services/request", "sitemap/lib/model"], function (_exports, _environment, _request, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ModelRequestService extends _request.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "factoryClass", _model.default);
    }

    async saveModel(model, opts) {
      let id = model.id;
      model.errors.clear();
      return Ember.isNone(id) ? this.postData(model, opts) : this.putData(model, opts);
    }

    async postData(model, opts = {}) {
      const response = await this.xhr(model, {
        method: 'POST',
        data: model.asJSON(opts)
      });
      return await this.handleResponse(model, response);
    }

    async putData(model, opts = {}) {
      const response = await this.xhr(model, {
        method: 'PUT',
        data: model.asJSON(opts)
      });
      return await this.handleResponse(model, response);
    }

    async xhr(model, opts = {}) {
      const url = opts.url ?? model.resourcePath;
      const endpointURL = new URL(url, _environment.default.apiURL);
      const body = opts.data ? JSON.stringify({
        data: opts.data
      }) : undefined;
      return await this.fetch(endpointURL, {
        method: opts.method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      });
    }

    async handleResponse(model, response) {
      const json = await response.json();

      if (response.ok) {
        model.setPropertiesFromJSON(json.data);
        return Ember.RSVP.resolve(model);
      } else {
        if (response.status === 422) {
          model.setErrorsFromJSON(json);
          return Ember.RSVP.reject(model);
        }

        throw json.errors;
      }
    }

    async destroyModel(model, opts = {}) {
      let id = model.id;

      if (Ember.isNone(id)) {
        return Ember.RSVP.reject({
          errorThrown: "can not destroy model without ID"
        });
      }

      let url = opts.url ?? model.resourcePath;
      url = new URL(url, _environment.default.apiURL);
      const response = await this.fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const json = await response.json();
        return Ember.RSVP.reject({
          errorThrown: json.data
        });
      }
    }

    async get(query) {
      const type = Ember.typeOf(query);
      const headers = {
        'Content-Type': 'application/vnd.api+json'
      };
      let baseUrl = this.factoryClass.path;
      let data;

      if (type === 'object') {
        data = query;
      } else if (type === 'string' || type === 'number') {
        baseUrl += '/' + query;
      }

      let url = new URL(baseUrl, _environment.default.apiURL);
      url.search = new URLSearchParams(data).toString();
      const response = await this.fetch(url, {
        method: 'GET',
        headers: headers
      });
      const responseData = await response.json();

      if (Ember.typeOf(responseData.data) === 'object') {
        return this.materializeRecord(responseData.data);
      } else if (Ember.typeOf(responseData.data) === 'array') {
        return responseData.data.map(d => this.materializeRecord(d));
      } else {
        return null;
      }
    }

    materializeRecord(attrs) {
      let record = this.factoryClass.create();
      record.setPropertiesFromJSON(attrs);
      return record;
    }

  }

  _exports.default = ModelRequestService;
});