define("sitemap/initializers/constants", ["exports", "sitemap/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    _environment.default.CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;
    const torii = {
      sessionServiceName: "session",
      providers: {
        "ungc-oauth2": {
          scope: "sitemap_access",
          apiKey: window.ENV?.CLIENT_ID,
          tokenExchangeUri: window.ENV?.API_URL + '/oauth/token'
        }
      }
    };
    _environment.default.apiURL = _environment.default.apiURL ?? window.ENV?.API_URL;
    _environment.default.torii = torii;
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});