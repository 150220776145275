define("sitemap/templates/images/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KW9mN78F",
    "block": "{\"symbols\":[\"flash\",\"@model\"],\"statements\":[[10,\"header\"],[14,1,\"main-page-header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-picture-o fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"Edit Image\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"images/x-image-edit\",[],[[\"@model\",\"@save\",\"@back\"],[[32,2],[32,0,[\"save\"]],[32,0,[\"back\"]]]],null],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1,[\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"flash\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"flash-message\",\"flashMessages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "sitemap/templates/images/edit.hbs"
    }
  });

  _exports.default = _default;
});