define("sitemap/templates/containers/needs-approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NoTdcp9n",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"header\"],[14,1,\"main-page-header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-check-square-o fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"Needs Approval\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"needs-approval\",[],[[\"@model\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sitemap/templates/containers/needs-approval.hbs"
    }
  });

  _exports.default = _default;
});