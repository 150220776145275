define("sitemap/templates/containers/edit-slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tr3DfopG",
    "block": "{\"symbols\":[\"flash\",\"@model\"],\"statements\":[[10,\"x-modal\"],[14,\"close\",\"dismiss\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Update Page Name\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group clearfix\"],[12],[2,\"\\n      \"],[8,\"validated-form-field\",[],[[\"@label\",\"@name\",\"@errors\"],[\"Page name (with dashes)\",\"slug\",[32,2,[\"container\",\"errors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"input\",[[24,\"data-test\",\"edit_name_input\"],[24,0,\"form-control\"],[24,1,\"slug\"]],[[\"@value\"],[[32,2,[\"container\",\"slug\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"has-error\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[2,\"\\n        Warning: all these changes apply immediately\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,\"data-test\",\"cancel_button\"],[24,0,\"btn btn-default\"],[24,\"data-dismiss\",\"modal\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"dismiss\"]],[32,2,[\"container\",\"id\"]]],null]],null],[12],[2,\"Cancel\"],[13],[2,\"\\n    \"],[11,\"button\"],[24,\"data-test\",\"edit_page_button\"],[24,0,\"btn btn-danger\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"saveContainer\"]],[32,2,[\"container\"]]],null]],null],[12],[2,\"Edit Page\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"flash\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flash-message\",\"fn\",\"on\",\"flashMessages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "sitemap/templates/containers/edit-slug.hbs"
    }
  });

  _exports.default = _default;
});