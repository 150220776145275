define("sitemap/routes/index", ["exports", "sitemap/lib/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _authenticatedRoute.default {
    beforeModel(transition) {
      super.beforeModel(transition);

      if (this.session.isAuthenticated) {
        this.replaceWith('containers.index'); // Implicitly aborts the on-going transition.
      }
    }

  }

  _exports.default = IndexRoute;
});