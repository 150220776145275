define("sitemap/components/images/x-list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    <table class="ungc-sitemap-table">
      <thead>
      <tr>
        <th class='image-preview'>Preview</th>
        <th class='image-filename'>Filename</th>
        <th class='image-url'>URL</th>
        <th class='image-destroy'>Delete</th>
      </tr>
      </thead>
      <tbody>
      {{#each @model as |image index|}}
        <Images::XImage @model={{image}} @dataTest={{concat "image_" index}} />
      {{/each}}
      </tbody>
    </table>
  
    <PageNumbers @content={{@model}} @action={{@pageClicked}} />
  </div>
  */
  {"id":"7qzuWMD2","block":"{\"symbols\":[\"image\",\"index\",\"@model\",\"@pageClicked\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"ungc-sitemap-table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,0,\"image-preview\"],[12],[2,\"Preview\"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"image-filename\"],[12],[2,\"Filename\"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"image-url\"],[12],[2,\"URL\"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"image-destroy\"],[12],[2,\"Delete\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"images/x-image\",[],[[\"@model\",\"@dataTest\"],[[32,1],[30,[36,0],[\"image_\",[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"page-numbers\",[],[[\"@content\",\"@action\"],[[32,3],[32,4]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"-track-array\",\"each\"]}","meta":{"moduleName":"sitemap/components/images/x-list.hbs"}});

  class ImagesXListComponent extends _component.default {}

  _exports.default = ImagesXListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ImagesXListComponent);
});