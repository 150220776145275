define("sitemap/routes/containers/new", ["exports", "sitemap/lib/authenticated-route", "sitemap/models/container"], function (_exports, _authenticatedRoute, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContainersNewRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class ContainersNewRoute extends _authenticatedRoute.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "layoutRequest", _descriptor, this);

      _initializerDefineProperty(this, "containerRequest", _descriptor2, this);
    }

    model(params) {
      let tree = this.modelFor('containers.index');
      let parent;

      if ('root' === params.parent_container) {
        parent = null;
      } else if (tree) {
        parent = tree.containerForId(params.parent_container);
      } else {
        parent = this.containerRequest.get(params.parent_container);
      }

      return Ember.RSVP.hash({
        layouts: this.layoutRequest.get(),
        container: _container.default.create(),
        parentContainer: parent
      });
    }

    afterModel(model) {
      let layout = model.layouts.firstObject;
      model.container.layoutRecord = layout;
      model.container.slug = '/';

      if (model.parentContainer) {
        model.container.set.publicPath = model.parentContainer.publicPath + '/';
        model.container.parentContainerId = model.parentContainer.id;
      } else {
        model.container.publicPath = '/';
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "layoutRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "containerRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContainersNewRoute;
});