define("sitemap/components/container/x-field", ["exports", "@glimmer/component", "sitemap/lib/titlecase"], function (_exports, _component, _titlecase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test={{@dataTest}}>
    {{#if (has-block)}}
      {{yield this}}
    {{else}}
      {{component this.fieldComponentName field=this}}
    {{/if}}
  </div>
  */
  {"id":"iuH+O/LW","block":"{\"symbols\":[\"&default\",\"@dataTest\"],\"statements\":[[10,\"div\"],[15,\"data-test\",[32,2]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"fieldComponentName\"]]],[[\"field\"],[[32,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"if\"]}","meta":{"moduleName":"sitemap/components/container/x-field.hbs"}});

  const TYPES = {
    'string': true,
    'string-addon': true,
    'number': true,
    'boolean': true,
    'image-upload': true,
    'hero-theme-select': true,
    'article-align-select': true,
    'tile-color-select': true,
    'button-color-select': true,
    'list-url-type-select': true,
    'label-select': true,
    'sorting': true,
    'text': true,
    'html': true,
    'href': true,
    'href-addon': true,
    'power-select': true,
    'redactor': true,
    'content-type-select': true
  };
  let ContainerXFieldComponent = (_dec = Ember.computed.alias('args.items'), _dec2 = Ember.computed('args.scope.errors.[]', 'path', function () {
    return this.args.scope.errors.filterBy('path', this.path);
  }), _dec3 = Ember.computed.bool('errors.length'), _dec4 = Ember.computed(function () {
    return `x-field-${Ember.guidFor(this)}`;
  }), _dec5 = Ember.computed('args.type', function () {
    let type = this.args.type;
    (true && !(TYPES[type]) && Ember.assert(`type "${type}" isn't in the list`, TYPES[type]));
    return `container/fields/x-${type}`;
  }), _dec6 = Ember.computed('args.scope.path', function () {
    // False positive ESLint error, it should not trigger for native classes
    this._resetScope();
  }), (_class = (_temp = class ContainerXFieldComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "items", _descriptor, this);

      _initializerDefineProperty(this, "errors", _descriptor2, this);

      _initializerDefineProperty(this, "hasErrors", _descriptor3, this);

      _initializerDefineProperty(this, "uid", _descriptor4, this);

      _initializerDefineProperty(this, "fieldComponentName", _descriptor5, this);

      _initializerDefineProperty(this, "_resetScopeObserver", _descriptor6, this);

      this._connectScope();
    }

    get label() {
      return this.args.label ?? (0, _titlecase.default)(this.args.key);
    }

    get type() {
      return this.args.type ?? null;
    }

    get key() {
      return this.args.key ?? null;
    }

    get scope() {
      return this.args.scope ?? null;
    }

    get labelPath() {
      return this.args.labelPath ?? null;
    }

    get allowClear() {
      return this.args.allowClear ?? null;
    }

    get placeholder() {
      return this.args.placeholder ?? null;
    }

    get limit() {
      return this.args.limit ?? null;
    }

    get multiple() {
      return this.args.multiple ?? null;
    }

    get default() {
      return this.args.default ?? null;
    }

    get array() {
      return this.args.array ?? null;
    }

    get min() {
      return this.args.min ?? null;
    }

    get max() {
      return this.args.max ?? null;
    }

    get size() {
      return this.args.size ?? null;
    } // TODO: LOL, of course we wish this worked.
    // errors: Ember.computed.filter('scope.errors', function(err) {
    //   return err.get('path') === this.get('path');
    // }),


    _resetScope() {
      this.path = ['data', this.args.scope.path, this.args.key].join('.');
    }

    _connectScope() {
      let defaultValue;
      let value;

      this._resetScope();

      Ember.defineProperty(this, 'value', Ember.computed.alias(`scope.data.${this.args.key}`));
      defaultValue = this.args.default;
      value = this.value;

      if (!defaultValue) {
        return;
      }

      if (Ember.isNone(value)) {
        return;
      }

      if ('boolean' === this.args.type && Ember.isNone(defaultValue)) {
        defaultValue = false;
      }

      this.value = defaultValue;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "items", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fieldComponentName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_resetScopeObserver", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContainerXFieldComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerXFieldComponent);
});