define("sitemap/components/containers/_forms/pr_list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @scope={{@model.container}} as |prList|>
    <Container::Macros::XMetatags @scope={{prList}} />
  
    <Container::Macros::XHero @scope={{prList}} as |hero|>
      <Container::Macros::XShowSectionNav @scope={{hero}} @default={{true}} />
    </Container::Macros::XHero>
  </Container::XFields>
  
  */
  {"id":"OFBsNYfo","block":"{\"symbols\":[\"prList\",\"hero\",\"@model\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@scope\"],[[32,3,[\"container\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container/macros/x-metatags\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-hero\",[],[[\"@scope\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/macros/x-show-section-nav\",[],[[\"@scope\",\"@default\"],[[32,2],true]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/containers/_forms/pr_list.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});