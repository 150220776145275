define("sitemap/torii-providers/ungc", ["exports", "torii/providers/oauth2-code", "sitemap/config/environment"], function (_exports, _oauth2Code, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UNGCToriiProvider extends _oauth2Code.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "name", 'ungc-oauth2');

      _defineProperty(this, "baseUrl", _environment.default.apiURL + '/oauth/authorize');

      _defineProperty(this, "responseParams", ['code']);
    }

    // redirectUri = config.torii.providers['ungc-oauth2'].redirectUri;
    fetch(data) {
      return data;
    }

  }

  _exports.default = UNGCToriiProvider;
});