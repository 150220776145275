define("sitemap/components/container/fields/x-sorting", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if @field.hasErrors "has-error"}}">
    <label class="control-label">
      {{@field.label}}
      <PowerSelect
              @class="form-control"
              @searchEnabled={{true}}
              @searchField="label"
              @placeholder="Sorting"
              @options={{this.items}}
              @selected={{this.selectedValue}}
              @onChange={{this.applySelection}} as |item|>
        {{item.label}}
      </PowerSelect>
    </label>
  
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  */
  {"id":"ur+j0bU+","block":"{\"symbols\":[\"item\",\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,2,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[2,\"\\n    \"],[1,[32,2,[\"label\"]]],[2,\"\\n    \"],[8,\"power-select\",[],[[\"@class\",\"@searchEnabled\",\"@searchField\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[\"form-control\",true,\"label\",\"Sorting\",[32,0,[\"items\"]],[32,0,[\"selectedValue\"]],[32,0,[\"applySelection\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,2,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,2,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-sorting.hbs"}});

  let ContainerFieldsXSortingComponent = (_dec = Ember.computed('args.field.value', function () {
    if (this.args.field.value) {
      return this.items.findBy('id', this.args.field.value.toString());
    }
  }), _dec2 = Ember._action, (_class = (_temp = class ContainerFieldsXSortingComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "items", [Ember.Object.create({
        id: 'asc',
        label: 'Ascending'
      }), Ember.Object.create({
        id: 'desc',
        label: 'Descending'
      })]);

      _initializerDefineProperty(this, "selectedValue", _descriptor, this);
    }

    applySelection(item) {
      this.args.field.value = item.id;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "applySelection", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "applySelection"), _class.prototype)), _class));
  _exports.default = ContainerFieldsXSortingComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXSortingComponent);
});