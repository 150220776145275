define("sitemap/services/payload-request", ["exports", "sitemap/services/model-request", "sitemap/models/payload"], function (_exports, _modelRequest, _payload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PayloadRequestService extends _modelRequest.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "endpointUrl", 'sitemap/api/payloads');

      _defineProperty(this, "factoryClass", _payload.default);
    }

  }

  _exports.default = PayloadRequestService;
});