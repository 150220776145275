define("sitemap/lib/active-model-serializer", ["exports", "@ember-data/serializer/rest", "@ember-data/store", "ember-inflector"], function (_exports, _rest, _store, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ActiveModelSerializer extends _rest.default {
    keyForAttribute(key, method) {
      return Ember.String.decamelize(key);
    }

    keyForRelationship(key, typeClass, method) {
      let decamelizedKey = Ember.String.decamelize(key);

      if (typeClass === "belongsTo") {
        return decamelizedKey + "_id";
      } else if (typeClass === "hasMany") {
        return (0, _emberInflector.singularize)(decamelizedKey) + "_ids";
      } else {
        return decamelizedKey;
      }
    }

    keyForLink(key, kind) {
      return Ember.String.camelize(key);
    }

    serializeHasMany() {}

    payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(Ember.String.decamelize(modelName));
    }

    serializePolymorphicType(snapshot, json, relationship) {
      let key = relationship.key;
      let typeKey = Ember.String.underscore(key + "_type");
      let belongsTo = snapshot.belongsTo(key);

      if (Ember.isNone(belongsTo)) {
        json[typeKey] = null;
      } else {
        json[typeKey] = Ember.String.classify(belongsTo.modelName).replace('/', '::');
      }
    }

    normalize(typeClass, hash, prop) {
      this.normalizeLinks(hash);
      return super.normalize(typeClass, hash, prop);
    }

    normalizeLinks(data) {
      if (data.links) {
        let links = data.links;

        for (let link in links) {
          let camelizedLink = Ember.String.camelize(link);

          if (camelizedLink !== link) {
            links[camelizedLink] = links[link];
            delete links[link];
          }
        }
      }
    }

    _keyForIDLessRelationship(key, relationshipType, type) {
      if (relationshipType === 'hasMany') {
        return Ember.String.underscore((0, _emberInflector.pluralize)(key));
      } else {
        return Ember.String.underscore((0, _emberInflector.singularize)(key));
      }
    }

    extractRelationships(modelClass, resourceHash) {
      modelClass.eachRelationship(function (key, relationshipMeta) {
        let relationshipKey = this.keyForRelationship(key, relationshipMeta.kind, "deserialize");

        let idLessKey = this._keyForIDLessRelationship(key, relationshipMeta.kind, "deserialize");

        if (resourceHash[idLessKey] && typeof relationshipMeta[relationshipKey] === 'undefined') {
          resourceHash[relationshipKey] = resourceHash[idLessKey];
        }

        if (relationshipMeta.options.polymorphic) {
          extractPolymorphicRelationships(key, relationshipMeta, resourceHash, relationshipKey);
        }

        if (resourceHash.hasOwnProperty(relationshipKey) && typeof resourceHash[relationshipKey] !== 'object') {
          let polymorphicTypeKey = this.keyForRelationship(key) + '_type';

          if (resourceHash[polymorphicTypeKey] && relationshipMeta.options.polymorphic) {
            let id = resourceHash[relationshipKey];
            let type = resourceHash[polymorphicTypeKey];
            delete resourceHash[polymorphicTypeKey];
            delete resourceHash[relationshipKey];
            resourceHash[relationshipKey] = {
              id: id,
              type: type
            };
          }
        }
      }, this);
      return super.extractRelationships.apply(this, arguments);
    }

    modelNameFromPayloadKey(key) {
      let convertedFromRubyModule = (0, _emberInflector.singularize)(key.replace('::', '/'));
      return (0, _store.normalizeModelName)(convertedFromRubyModule);
    }

  }

  _exports.default = ActiveModelSerializer;

  function extractPolymorphicRelationships(key, relationshipMeta, resourceHash, relationshipKey) {
    let polymorphicKey = Ember.String.decamelize(key);
    let hash = resourceHash[polymorphicKey];

    if (hash !== null && typeof hash === 'object') {
      resourceHash[relationshipKey] = hash;
    }
  }
});