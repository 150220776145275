define("sitemap/lib/sitemap/node", ["exports", "sitemap/models/container"], function (_exports, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Node = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('nodes.[]', 'hasDescendants', function () {
    return this.nodes.length === 0 && this.hasDescendants;
  }), (_class = (_temp = class Node extends Ember.Object {
    initNodes() {
      this.nodes = [];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "containerRequest", _descriptor, this);

      _initializerDefineProperty(this, "modelId", _descriptor2, this);

      _initializerDefineProperty(this, "model", _descriptor3, this);

      _initializerDefineProperty(this, "tree", _descriptor4, this);

      _initializerDefineProperty(this, "hasDescendants", _descriptor5, this);

      _initializerDefineProperty(this, "isLoading", _descriptor6, this);

      _initializerDefineProperty(this, "hasMore", _descriptor7, this);

      this.initNodes();
    }

    addChild(node) {
      this.nodes.addObject(node);
    }

    async load() {
      let tree = this.tree; //if (!this.hasMore) {
      //  return Ember.RSVP.resolve();
      //}

      if (!this.model) {
        return Ember.RSVP.resolve();
      }

      const containers = await this.containerRequest.get({
        parent_container: this.modelId
      });
      containers.forEach(container => tree.addContainer(container));
      this.isLoading = false;
      return containers;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "containerRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tree", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasDescendants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasMore", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Node;
});