define("sitemap/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii", "sitemap/config/environment"], function (_exports, _torii, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ToriiAuthenticator = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class ToriiAuthenticator extends _torii.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "torii", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _defineProperty(this, "scope", _environment.default.torii.providers['ungc-oauth2'].scope);

      _defineProperty(this, "clientId", _environment.default.torii.providers['ungc-oauth2'].apiKey);

      _defineProperty(this, "redirectUri", _environment.default.torii.providers['ungc-oauth2'].redirectUri);

      _defineProperty(this, "tokenExchangeUri", _environment.default.torii.providers['ungc-oauth2'].tokenExchangeUri);

      _defineProperty(this, "refreshAccessTokens", true);

      _defineProperty(this, "_refreshTokenTimeout", null);
    }

    get tokenRefreshOffset() {
      const min = 5;
      const max = 10;
      return (Math.floor(Math.random() * (max - min)) + min) * 1000;
    }

    authenticate() {
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        const data = await super.authenticate(...arguments);
        const body = {
          grant_type: 'authorization_code',
          code: data.authorizationCode,
          redirect_uri: data.redirectUri,
          client_id: this.clientId
        };
        const headers = {
          method: "POST",
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        };
        this.makeRequest(this.tokenExchangeUri, body, headers).then(response => {
          Ember.run(() => {
            if (!this._validate(response)) {
              reject('access_token is missing in server response');
            }

            const expiresAt = this._absolutizeExpirationTime(response['expires_in']);

            this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token'], data.provider);

            if (!Ember.isEmpty(expiresAt)) {
              response = Object.assign(response, {
                'expires_at': expiresAt,
                'provider': data.provider
              });
            }

            resolve(response);
          });
        }, response => {
          Ember.run(null, reject, response);
        });
      });
    }

    async restore(data) {
      data = await super.restore(...arguments);
      return new Ember.RSVP.Promise((resolve, reject) => {
        const now = new Date().getTime();

        if (!Ember.isEmpty(data['expires_at']) && data['expires_at'] < now) {
          if (this.refreshAccessTokens) {
            this._refreshAccessToken(data['expires_in'], data['refresh_token'], data['provider']).then(resolve, reject);
          } else {
            reject();
          }
        } else {
          if (!this._validate(data)) {
            reject();
          } else {
            this._scheduleAccessTokenRefresh(data['expires_in'], data['expires_at'], data['refresh_token'], data['provider']);

            resolve(data);
          }
        }
      });
    }

    async makeRequest(url, data, headers = {}) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';

      if (!Ember.isEmpty(this.clientId)) {
        data['client_id'] = this.clientId;
      }

      const body = Object.keys(data).map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
      }).join('&');
      const options = {
        body,
        headers,
        method: 'POST'
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        fetch(url, options).then(response => {
          response.text().then(text => {
            try {
              let json = JSON.parse(text);

              if (!response.ok) {
                response.responseJSON = json;
                reject(response);
              } else {
                resolve(json);
              }
            } catch (SyntaxError) {
              response.responseText = text;
              reject(response);
            }
          });
        }).catch(reject);
      });
    }

    _refreshAccessToken(expiresIn, refreshToken, provider) {
      const data = {
        'client_id': this.clientId,
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken,
        'redirect_uri': this.redirectUri
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.makeRequest(this.tokenExchangeUri, data).then(response => {
          Ember.run(() => {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;

            const expiresAt = this._absolutizeExpirationTime(expiresIn);

            const data = Object.assign(response, {
              'expires_in': expiresIn,
              'expires_at': expiresAt,
              'refresh_token': refreshToken,
              provider: provider
            });

            this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken, provider);

            this.trigger('sessionDataUpdated', data);
            resolve(data);
          });
        }, response => {
          (true && Ember.warn(`Access token could not be refreshed - server responded with ${response.responseJSON}.`, false, {
            id: 'ember-simple-auth.failedOAuth2TokenRefresh'
          }));
          reject();
        });
      });
    }

    _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken, provider) {
      if (this.refreshAccessTokens) {
        const now = new Date().getTime();

        if (Ember.isEmpty(expiresAt) && !Ember.isEmpty(expiresIn)) {
          expiresAt = new Date(now + expiresIn * 1000).getTime();
        }

        const offset = this.tokenRefreshOffset;

        if (!Ember.isEmpty(refreshToken) && !Ember.isEmpty(expiresAt) && expiresAt > now - offset) {
          Ember.run.cancel(this._refreshTokenTimeout);
          delete this._refreshTokenTimeout;

          if (_environment.default.environment !== 'test') {
            this._refreshTokenTimeout = Ember.run.later(this, this._refreshAccessToken, expiresIn, refreshToken, provider, expiresAt - now - offset);
          }
        }
      }
    }

    _absolutizeExpirationTime(expiresIn) {
      if (!Ember.isEmpty(expiresIn)) {
        return new Date(new Date().getTime() + expiresIn * 1000).getTime();
      }
    }

    _validate(data) {
      return !Ember.isEmpty(data['access_token']);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "torii", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ToriiAuthenticator;
});