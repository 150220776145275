define("sitemap/controllers/containers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContainersEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ContainersEditController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "containerRequest", _descriptor2, this);

      _initializerDefineProperty(this, "payloadRequest", _descriptor3, this);
    }

    saveDraft(container) {
      this.containerRequest.saveModel(container).then(() => {
        this.flashMessages.success('Draft Saved!');
      }, error => {
        if (error.errorThrown) {
          this.flashMessages.danger(error.statusText);
          throw error.toString();
        } else {
          this.flashMessages.danger('Validation Error!');
        }
      });
    }

    setDraftFromPayload(payload) {
      let container;

      if (!confirm('This will discard the current draft and revert to this previously published version.')) {
        return;
      }

      container = this.model.container;
      container.setPropertiesFromJSON({
        data: payload.asJSON().data
      });
    }

    async publish(container) {
      let payloads = this.model.payloads;

      if (!confirm('Are you sure you want to publish this page?')) {
        return;
      }

      const response = await this.containerRequest.publish(container);

      if (response.ok) {
        this.flashMessages.success('Draft Published!');
        this.payloadRequest.get({
          container: container.id
        }).then(records => {
          records.toArray().reverse().forEach(record => {
            if (payloads.findBy('id', record.id)) {
              return;
            }

            payloads.insertAt(0, record);
          });
        });
      }
    }

    destroyModel(container) {
      if (confirm('This will destroy this page, it can not be undone')) {
        this.containerRequest.destroyModel(container).then(() => {
          this.transitionToRoute('containers.index');
        }).catch(error => {
          if (error.errorThrown) {
            this.flashMessages.danger(error.errorThrown);
          }
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "containerRequest", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "payloadRequest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveDraft", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "saveDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDraftFromPayload", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setDraftFromPayload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyModel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "destroyModel"), _class.prototype)), _class));
  _exports.default = ContainersEditController;
});