define("sitemap/components/container/macros/x-link", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @key={{@key}} @scope={{@scope}} as |link|>
    <h2>Link</h2>
    <Container::XField @key="label" @type="string-addon" @label="Text" @scope={{link}} />
    <Container::XField @key="url" @type="href-addon" @scope={{link}} />
  </Container::XFields>
  
  */
  {"id":"hdcLL//I","block":"{\"symbols\":[\"link\",\"@key\",\"@scope\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Link\"],[13],[2,\"\\n  \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@label\",\"@scope\"],[\"label\",\"string-addon\",\"Text\",[32,1]]],null],[2,\"\\n  \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"url\",\"href-addon\",[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-link.hbs"}});

  class ContainerMacrosXLinkComponent extends _component.default {}

  _exports.default = ContainerMacrosXLinkComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXLinkComponent);
});