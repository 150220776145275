define("sitemap/components/container-index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.scrollTop}}>
    <header id='main-page-header'>
      <h1><i class='fa fa-sitemap fa-fw' aria-hidden='true'></i>Sitemap</h1>
    </header>
  
    <XTree @tree={{@model}} @addContainer={{this.addContainer}} @moveContainer={{this.moveContainer}} />
  
    {{yield}}
  
    {{#each flashMessages.queue as |flash|}}
      {{flash-message flash=flash}}
    {{/each}}
  </div>
  */
  {"id":"migqnvX/","block":"{\"symbols\":[\"flash\",\"@model\",\"&default\"],\"statements\":[[11,\"div\"],[4,[38,1],[[32,0,[\"scrollTop\"]]],null],[12],[2,\"\\n  \"],[10,\"header\"],[14,1,\"main-page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-sitemap fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"Sitemap\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"x-tree\",[],[[\"@tree\",\"@addContainer\",\"@moveContainer\"],[[32,2],[32,0,[\"addContainer\"]],[32,0,[\"moveContainer\"]]]],null],[2,\"\\n\\n  \"],[18,3,null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"flash\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"flash-message\",\"did-insert\",\"flashMessages\",\"-track-array\",\"each\"]}","meta":{"moduleName":"sitemap/components/container-index.hbs"}});

  let ContainerIndexComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ContainerIndexComponent extends _component.default {
    scrollTop() {
      Ember.run.later(function () {
        document.querySelector('body').scrollTop = 0;
      }, 20);
    }

    moveContainer(a) {
      this.args.moveContainer(a);
    }

    addContainer(parentContainer) {
      this.args.addContainer(parentContainer);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "moveContainer", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "moveContainer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addContainer", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addContainer"), _class.prototype)), _class));
  _exports.default = ContainerIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerIndexComponent);
});