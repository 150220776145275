define("sitemap/components/needs-approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class='ungc-sitemap-table'>
    <thead>
    <tr>
      <th>Path</th>
      <th>Layout</th>
      <th>Last Edited by</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    {{#each @model as |container index|}}
      <tr data-test={{concat "draft_" index}}>
        <th data-test="path">
          <code>{{~container.publicPath~}}</code>
        </th>
        <td data-test="layout">
          <span class='label label-default'><i class='fa fa-columns fa-fw' aria-hidden='true'></i>&nbsp;{{container.layout}}</span>
        </td>
        <td data-test="published_info">
          <Container::Info::PublishedBy @model={{container}} />
        </td>
        <td data-test="edit_button">
          {{!-- NOTE: We call .id here to force the route to load the model hook --}}
          <LinkTo @route="containers.edit" @model={{container.id}} class="btn btn-primary">
            <i class='fa fa-pencil fa-fw' aria-hidden='true'></i>&nbsp;Edit
          </LinkTo>
        </td>
      </tr>
    {{/each}}
    </tbody>
  </table>
  
  */
  {"id":"8w1TPyoV","block":"{\"symbols\":[\"container\",\"index\",\"@model\"],\"statements\":[[10,\"table\"],[14,0,\"ungc-sitemap-table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Path\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Layout\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Last Edited by\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Actions\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[15,\"data-test\",[30,[36,0],[\"draft_\",[32,2]],null]],[12],[2,\"\\n      \"],[10,\"th\"],[14,\"data-test\",\"path\"],[12],[2,\"\\n        \"],[10,\"code\"],[12],[1,[32,1,[\"publicPath\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[14,\"data-test\",\"layout\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"label label-default\"],[12],[10,\"i\"],[14,0,\"fa fa-columns fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" \"],[1,[32,1,[\"layout\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[14,\"data-test\",\"published_info\"],[12],[2,\"\\n        \"],[8,\"container/info/published-by\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[14,\"data-test\",\"edit_button\"],[12],[2,\"\\n\"],[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@model\"],[\"containers.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-pencil fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Edit\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"-track-array\",\"each\"]}","meta":{"moduleName":"sitemap/components/needs-approval.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});