define("sitemap/components/containers/_forms/news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @scope={{@model.container}} as |news|>
    <Container::Macros::XMetatags @scope={{news}} />
  
    <Container::Macros::XHero @scope={{news}} />
  
    <Container::Macros::XWidgetContact @scope={{news}} />
  
    <Container::Macros::XWidgetCallsToAction @scope={{news}} />
  
    <Container::Macros::XRelatedContents @scope={{news}} />
  
    <Container::Macros::XResources @scope={{news}} />
  
  </Container::XFields>
  
  */
  {"id":"5GQLybDd","block":"{\"symbols\":[\"news\",\"@model\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@scope\"],[[32,2,[\"container\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container/macros/x-metatags\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-hero\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-widget-contact\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-widget-calls-to-action\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-related-contents\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-resources\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/containers/_forms/news.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});