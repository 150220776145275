define("sitemap/services/container-request", ["exports", "sitemap/services/model-request", "sitemap/models/container"], function (_exports, _modelRequest, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ContainerRequestService extends _modelRequest.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "endpointUrl", 'sitemap/api/containers');

      _defineProperty(this, "factoryClass", _container.default);
    }

    async putData(model, opts = {}) {
      const response = await this.xhr(model, {
        method: 'PUT',
        data: model.asJSON(opts)
      });
      return await this.handlePutResponse(model, response);
    } // XXX override handleResponse to avoid resetting properties in case of success
    // otherwise fields become unbound


    async handlePutResponse(model, response) {
      const json = await response.json();

      if (response.ok) {
        return Ember.RSVP.resolve(model);
      } else {
        if (response.status === 422) {
          model.setErrorsFromJSON(json);
          return Ember.RSVP.reject(model);
        }

        throw json.errors;
      }
    }

    async publish(model) {
      await this.saveModel(model, {});
      return await this.xhr(model, {
        method: 'POST',
        url: model.resourcePath + '/publish'
      });
    }

    async getNeedsApproval() {
      return this.get('needs_approval');
    }

  }

  _exports.default = ContainerRequestService;
});