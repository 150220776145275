define("sitemap/router", ["exports", "sitemap/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('containers', function () {
      this.route('needs-approval');
      this.route('new', {
        path: '/new/in/:parent_container'
      });
      this.route('edit', {
        path: '/:container_id'
      });
      this.route('edit-slug', {
        path: '/:container_id/edit-slug'
      });
    });
    this.route('images', function () {
      this.route('edit', {
        path: '/:image_id'
      });
    });
    this.route('login');
  });
});