define("sitemap/components/container/macros/x-metatags", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Meta Tags</legend>
    <Container::XFields @key="metaTags" @scope={{@scope}} as |metaTags|>
      <Container::XField @key="title" @type="string" @scope={{metaTags}} @dataTest="metatag_title" />
      <Container::XField @key="description" @type="string" @scope={{metaTags}} @dataTest="metatag_description" />
      <Container::XField @key="thumbnail" @type="image-upload" @scope={{metaTags}} @dataTest="metatag_thumbnail" />
      <Container::XField @key="label" @type="label-select" @scope={{metaTags}} />
      <Container::XField @key="keywords" @type="string" @scope={{metaTags}} />
      {{yield metaTags}}
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"TyEE7eFI","block":"{\"symbols\":[\"metaTags\",\"@scope\",\"&default\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Meta Tags\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[\"metaTags\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\",\"@dataTest\"],[\"title\",\"string\",[32,1],\"metatag_title\"]],null],[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\",\"@dataTest\"],[\"description\",\"string\",[32,1],\"metatag_description\"]],null],[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\",\"@dataTest\"],[\"thumbnail\",\"image-upload\",[32,1],\"metatag_thumbnail\"]],null],[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"label\",\"label-select\",[32,1]]],null],[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"keywords\",\"string\",[32,1]]],null],[2,\"\\n    \"],[18,3,[[32,1]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-metatags.hbs"}});

  class ContainerMacrosXMetatagsComponent extends _component.default {}

  _exports.default = ContainerMacrosXMetatagsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXMetatagsComponent);
});