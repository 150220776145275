define("sitemap/services/image-upload-request", ["exports", "sitemap/services/request", "sitemap/config/environment"], function (_exports, _request, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImageUploadRequestService extends _request.default {
    async getSignedUrl(filename) {
      const endpointURL = new URL("/sitemap/api/images/signed_url", _environment.default.apiURL);
      endpointURL.search = new URLSearchParams({
        filename: filename
      }).toString();
      return await this.fetch(endpointURL, {
        method: "POST"
      });
    }

    saveUploadedImage(url, filename) {
      const endpointURL = new URL("/sitemap/api/images", _environment.default.apiURL);
      return this.fetch(endpointURL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          image: {
            url: url,
            filename: filename
          }
        })
      });
    }

  }

  _exports.default = ImageUploadRequestService;
});