define("sitemap/lib/model", ["exports", "sitemap/lib/transform-keys"], function (_exports, _transformKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function underscoreKeys(obj) {
    return (0, _transformKeys.default)(obj, k => k.underscore());
  }

  function camelizeKeys(obj) {
    return (0, _transformKeys.default)(obj, k => k.camelize());
  }

  function pojoize(src) {
    let type = Ember.typeOf(src);

    if (type === 'array') {
      return src.map(e => pojoize(e));
    } else if (type === 'instance' || type === 'object') {
      return Object.keys(src).reduce(function (json, key) {
        json[key] = pojoize(src[key]);
        return json;
      }, {});
    } else {
      return src;
    }
  }

  let Model = (_dec = Ember.computed(function () {
    return this.constructor.type;
  }), _dec2 = Ember.computed('id', function () {
    let id = this.id;

    if (Ember.isNone(id)) {
      return this.constructor.path;
    } else {
      return this.constructor.path + '/' + id;
    }
  }), (_class = (_temp = class Model extends Ember.Object {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "resourcePath", _descriptor2, this);
    }

    initializeErrorsArray() {
      this.errors = [];
    }

    init() {
      this.initializeErrorsArray();
    }

    setPropertiesFromJSON(json) {
      this.setProperties(camelizeKeys(json));
    }

    setErrorsFromJSON(jsonErrors) {
      let errors = jsonErrors.errors.map(e => {
        e.path = e.path.split('.').map(p => p.camelize()).join('.');
        return camelizeKeys(e);
      });
      this.errors.clear();
      this.errors.pushObjects(errors);
    }

    asJSON(opts = {}) {
      let json = {};
      let id;
      let without = opts.without || [];

      if (id = this.id) {
        json.id = id;
      }

      json.type = this.constructor.type;
      return underscoreKeys(this.constructor.attributes.reduce((attrs, attr) => {
        let val = this[attr];

        if (val !== undefined && !without.includes(attr)) {
          attrs[attr] = pojoize(val);
        }

        return attrs;
      }, json));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "resourcePath", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  Model.reopenClass({
    path: null
  });
  var _default = Model;
  _exports.default = _default;
});