define("sitemap/serializers/application", ["exports", "sitemap/lib/active-model-serializer"], function (_exports, _activeModelSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _activeModelSerializer.default {}

  _exports.default = ApplicationSerializer;
});