define("sitemap/components/images/x-image", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
    <td class="image-preview">
      <img src={{@model.url}} style="max-width: 300px">
    </td>
    <th class="image-filename">
      {{@model.filename}}
    </th>
    <td class="image-url">
      <input type="text" value="{{@model.url}}" readonly="readonly" class="form-control image-url-input">
    </td>
    <td class="image-destroy" data-test={{@dataTest}}>
      <button class="btn btn-danger" disabled={{this.isDisabled}} {{on "click" (fn this.destroy @model)}} data-test="delete_button">Delete</button>
      <LinkTo @route="images.edit" @model={{@model}} class="btn btn-warning" data-test="edit_button">edit</LinkTo>
    </td>
  </tr>
  
  */
  {"id":"Nj62TvpB","block":"{\"symbols\":[\"@model\",\"@dataTest\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"image-preview\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[32,1,[\"url\"]]],[14,5,\"max-width: 300px\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"th\"],[14,0,\"image-filename\"],[12],[2,\"\\n    \"],[1,[32,1,[\"filename\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"image-url\"],[12],[2,\"\\n    \"],[10,\"input\"],[15,2,[31,[[32,1,[\"url\"]]]]],[14,\"readonly\",\"readonly\"],[14,0,\"form-control image-url-input\"],[14,4,\"text\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"image-destroy\"],[15,\"data-test\",[32,2]],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[16,\"disabled\",[32,0,[\"isDisabled\"]]],[24,\"data-test\",\"delete_button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"destroy\"]],[32,1]],null]],null],[12],[2,\"Delete\"],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-warning\"],[24,\"data-test\",\"edit_button\"]],[[\"@route\",\"@model\"],[\"images.edit\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"edit\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}","meta":{"moduleName":"sitemap/components/images/x-image.hbs"}});

  let ImagesXImageComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class ImagesXImageComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isDisabled", _descriptor, this);
    }

    destroy(image) {
      this.isDisabled = true;
      image.destroyRecord();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "destroy", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "destroy"), _class.prototype)), _class));
  _exports.default = ImagesXImageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ImagesXImageComponent);
});