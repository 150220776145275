define("sitemap/components/container/macros/x-topics", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.setItems}}>
    <Container::XField @key="topics" @type="power-select" @labelPath="name" @placeholder="Please choose some topics" @multiple={{true}} @limit=50 @scope={{@scope}} @items={{this.items}} @allowClear={{true}} />
  </div>
  */
  {"id":"4A0gu5L5","block":"{\"symbols\":[\"@scope\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setItems\"]]],null],[12],[2,\"\\n  \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@labelPath\",\"@placeholder\",\"@multiple\",\"@limit\",\"@scope\",\"@items\",\"@allowClear\"],[\"topics\",\"power-select\",\"name\",\"Please choose some topics\",true,\"50\",[32,1],[32,0,[\"items\"]],true]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}","meta":{"moduleName":"sitemap/components/container/macros/x-topics.hbs"}});

  let ContainerMacrosXTopicsComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class ContainerMacrosXTopicsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "topics", _descriptor, this);

      _initializerDefineProperty(this, "items", _descriptor2, this);
    }

    async setItems() {
      this.items = await this.topics.getData();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "topics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setItems", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setItems"), _class.prototype)), _class));
  _exports.default = ContainerMacrosXTopicsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXTopicsComponent);
});