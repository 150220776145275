define("sitemap/adapters/sector", ["exports", "sitemap/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SectorAdapter extends _application.default {
    pathForType() {
      return "taggings/sectors";
    }

  }

  _exports.default = SectorAdapter;
});