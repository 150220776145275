define("sitemap/components/container/fields/x-boolean", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if @field.hasErrors "has-error"}}">
    <div class="checkbox">
      <label>
        <Input @type="checkbox" @checked={{@field.value}} />
        {{@field.label}}
      </label>
    </div>
  
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  */
  {"id":"KKccxCYL","block":"{\"symbols\":[\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,1,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,1,[\"value\"]]]],null],[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,1,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-boolean.hbs"}});

  class ContainerFieldsXBooleanComponent extends _component.default {}

  _exports.default = ContainerFieldsXBooleanComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXBooleanComponent);
});