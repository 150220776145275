define("sitemap/components/container/macros/x-taggings", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Taxonomy</legend>
    <Container::XFields @key="taggings" @scope={{@scope}} as |taggings|>
      <Container::Macros::XIssues @scope={{taggings}} />
      <Container::Macros::XTopics @scope={{taggings}} />
      <Container::Macros::XSectors @scope={{taggings}} />
      <Container::Macros::XSustainableDevelopmentGoals @scope={{taggings}} />
      <Container::Macros::XActionPlatforms @scope={{taggings}} />
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"SCEJ/au3","block":"{\"symbols\":[\"taggings\",\"@scope\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Taxonomy\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\"],[\"taggings\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/macros/x-issues\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"container/macros/x-topics\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"container/macros/x-sectors\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"container/macros/x-sustainable-development-goals\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n    \"],[8,\"container/macros/x-action-platforms\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-taggings.hbs"}});

  class ContainerMacrosXTaggingsComponent extends _component.default {}

  _exports.default = ContainerMacrosXTaggingsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXTaggingsComponent);
});