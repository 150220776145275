define("sitemap/components/container/fields/x-string-addon", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if @field.hasErrors "has-error"}}">
    <div class='input-group'>
      <label class='input-group-addon' for={{@field.uid}}>{{@field.label}}</label>
      <Input
              @type="text"
              class="form-control"
              id={{@field.uid}}
              @value={{@field.value}}
      />
    </div>
  
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  
  */
  {"id":"HSL2AWB+","block":"{\"symbols\":[\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,0],[[32,1,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"input-group-addon\"],[15,\"for\",[32,1,[\"uid\"]]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"],[16,1,[32,1,[\"uid\"]]]],[[\"@type\",\"@value\"],[\"text\",[32,1,[\"value\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,1,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-string-addon.hbs"}});

  class ContainerFieldsXStringAddonComponent extends _component.default {}

  _exports.default = ContainerFieldsXStringAddonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXStringAddonComponent);
});