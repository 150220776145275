define("sitemap/components/containers/_forms/library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @scope={{@model.container}} as |library|>
    <Container::Macros::XMetatags @scope={{library}} />
  
    <Container::Macros::XHero @scope={{library}} />
  
    <fieldset>
      <legend>Featured</legend>
      <Container::XFields @key="featured" @array={{true}} @size=12 @scope={{library}} as |resources|>
        <li class="stat-group">
          <Container::Macros::XResource @key="resourceId" @scope={{resources}} />
        </li>
      </Container::XFields>
    </fieldset>
  </Container::XFields>
  
  */
  {"id":"ARcc6oca","block":"{\"symbols\":[\"library\",\"resources\",\"@model\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@scope\"],[[32,3,[\"container\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container/macros/x-metatags\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[8,\"container/macros/x-hero\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\\n  \"],[10,\"fieldset\"],[12],[2,\"\\n    \"],[10,\"legend\"],[12],[2,\"Featured\"],[13],[2,\"\\n    \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@size\",\"@scope\"],[\"featured\",true,\"12\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"stat-group\"],[12],[2,\"\\n        \"],[8,\"container/macros/x-resource\",[],[[\"@key\",\"@scope\"],[\"resourceId\",[32,2]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/containers/_forms/library.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});