define("sitemap/components/container/macros/x-related-contents", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Related Content</legend>
    <Container::XFields @key="relatedContents" @array={{true}} @min=0 @max=2 @scope={{@scope}} as |relatedContent|>
      <Container::XField @key="title" @type="string" @limit=50 @scope={{relatedContent}} />
      <Container::XFields @key="contentBoxes" @array={{true}} @size=3 @scope={{relatedContent}} as |boxes|>
        <li class="stat-group">
          <Container::XField @key="containerPath" @type="string" @scope={{boxes}} />
        </li>
      </Container::XFields>
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"awmB0FW8","block":"{\"symbols\":[\"relatedContent\",\"boxes\",\"@scope\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Related Content\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@min\",\"@max\",\"@scope\"],[\"relatedContents\",true,\"0\",\"2\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@limit\",\"@scope\"],[\"title\",\"string\",\"50\",[32,1]]],null],[2,\"\\n    \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@size\",\"@scope\"],[\"contentBoxes\",true,\"3\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"stat-group\"],[12],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"containerPath\",\"string\",[32,2]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-related-contents.hbs"}});

  class ContainerMacrosXRelatedContentsComponent extends _component.default {}

  _exports.default = ContainerMacrosXRelatedContentsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXRelatedContentsComponent);
});