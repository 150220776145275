define("sitemap/routes/images/index", ["exports", "sitemap/lib/authenticated-route", "ember-cli-pagination/remote/route-mixin"], function (_exports, _authenticatedRoute, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ImagesIndexRoute extends _authenticatedRoute.default.extend(_routeMixin.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        query: {
          refreshModel: true
        }
      });
    }

    model(params) {
      return this.findPaged('image', params);
    }

  }

  _exports.default = ImagesIndexRoute;
});