define("sitemap/templates/images/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RcfiBwjU",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"header\"],[14,1,\"main-page-header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-picture-o fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"Uploaded Images\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"images/x-search\",[],[[\"@query\",\"@search\"],[[32,0,[\"query\"]],[32,0,[\"search\"]]]],null],[2,\"\\n\"],[8,\"images/x-list\",[],[[\"@model\",\"@pageClicked\"],[[32,1],[32,0,[\"pageClicked\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sitemap/templates/images/index.hbs"
    }
  });

  _exports.default = _default;
});