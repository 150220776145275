define("sitemap/routes/login", ["exports", "sitemap/lib/unauthenticated-route"], function (_exports, _unauthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends _unauthenticatedRoute.default {}

  _exports.default = LoginRoute;
});