define("sitemap/components/containers/_forms/all_issue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container::XFields @scope={{@model.container}} as |allIssues|>
    <Container::Macros::XMetatags @scope={{allIssues}} />
  </Container::XFields>
  
  */
  {"id":"ZRCUmgDK","block":"{\"symbols\":[\"allIssues\",\"@model\"],\"statements\":[[8,\"container/x-fields\",[],[[\"@scope\"],[[32,2,[\"container\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"container/macros/x-metatags\",[],[[\"@scope\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/containers/_forms/all_issue.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});