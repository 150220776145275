define("sitemap/components/container/fields/x-power-select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group {{if @field.hasErrors "has-error"}}">
    <label class="control-label" for={{@field.uid}}>
      {{@field.label}}
    </label>
  
    {{#if @field.multiple}}
      <PowerSelectMultiple
              @allowClear={{@field.allowClear}}
              @searchEnabled={{true}}
              @searchField={{@field.labelPath}}
              @placeholder={{@field.placeholder}}
              @options={{@field.items}}
              @selected={{this.selectedValues}}
              @onChange={{this.applySelections}} as |selections|>
        {{get selections @field.labelPath}}
      </PowerSelectMultiple>
    {{else}}
      <PowerSelect
              @allowClear={{@field.allowClear}}
              @searchEnabled={{true}}
              @searchField={{@field.labelPath}}
              @placeholder={{@field.placeholder}}
              @options={{@field.items}}
              @selected={{this.selectedValue}}
              @onChange={{this.applySelection}} as |selection|>
        {{get selection @field.labelPath}}
      </PowerSelect>
    {{/if}}
  
    {{#if @field.errors}}
      <p class="help-block">{{@field.errors.firstObject.detail}}</p>
    {{/if}}
  </div>
  
  */
  {"id":"Y23CISPH","block":"{\"symbols\":[\"selection\",\"selections\",\"@field\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,1],[[32,3,[\"hasErrors\"]],\"has-error\"],null]]]],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[32,3,[\"uid\"]]],[12],[2,\"\\n    \"],[1,[32,3,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,3,[\"multiple\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"power-select-multiple\",[],[[\"@allowClear\",\"@searchEnabled\",\"@searchField\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[[32,3,[\"allowClear\"]],true,[32,3,[\"labelPath\"]],[32,3,[\"placeholder\"]],[32,3,[\"items\"]],[32,0,[\"selectedValues\"]],[32,0,[\"applySelections\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,2],[32,3,[\"labelPath\"]]],null]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"power-select\",[],[[\"@allowClear\",\"@searchEnabled\",\"@searchField\",\"@placeholder\",\"@options\",\"@selected\",\"@onChange\"],[[32,3,[\"allowClear\"]],true,[32,3,[\"labelPath\"]],[32,3,[\"placeholder\"]],[32,3,[\"items\"]],[32,0,[\"selectedValue\"]],[32,0,[\"applySelection\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[32,1],[32,3,[\"labelPath\"]]],null]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,3,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[32,3,[\"errors\",\"firstObject\",\"detail\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"if\"]}","meta":{"moduleName":"sitemap/components/container/fields/x-power-select.hbs"}});

  let ContainerFieldsXPowerSelectComponent = (_dec = Ember.computed('args.field.{items,value}', function () {
    if (this.args.field.items && this.args.field.value) {
      return this.args.field.items.findBy('id', this.args.field.value.toString());
    }
  }), _dec2 = Ember.computed('args.field.{items,value}', function () {
    if (this.args.field.items && this.args.field.value) {
      return this.args.field.value.map(id => {
        return this.args.field.items.findBy('id', id.toString());
      });
    }
  }), _dec3 = Ember.computed('args.field.items.[]', function () {
    let items = this.args.field.items;

    if (!items) {
      return;
    }

    let exists = items.any(i => {
      let val = this.args.field.value;

      if (val instanceof Array) {
        return true;
      } else {
        let id = parseInt(i.get('id'), 10);
        return id === val;
      }
    });

    if (!exists) {
      this.set('field.value', null);
    }
  }), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ContainerFieldsXPowerSelectComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "field", null);

      _defineProperty(this, "labelPath", null);

      _defineProperty(this, "placeholder", null);

      _defineProperty(this, "allowClear", false);

      _defineProperty(this, "multiple", false);

      _initializerDefineProperty(this, "selectedValue", _descriptor, this);

      _initializerDefineProperty(this, "selectedValues", _descriptor2, this);

      _initializerDefineProperty(this, "onItems", _descriptor3, this);
    }

    applySelection(item) {
      if (item) {
        this.args.field.value = item.id;
      } else {
        this.args.field.value = null;
      }
    }

    applySelections(items) {
      this.args.field.value = items.map(item => {
        return item.id;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedValues", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "applySelection", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "applySelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applySelections", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "applySelections"), _class.prototype)), _class));
  _exports.default = ContainerFieldsXPowerSelectComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerFieldsXPowerSelectComponent);
});