define("sitemap/lib/active-model-adapter", ["exports", "@ember-data/adapter/rest", "ember-inflector"], function (_exports, _rest, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ActiveModelAdapter extends _rest.default {
    pathForType(modelName) {
      let decamelized = Ember.String.decamelize(modelName);
      let underscored = Ember.String.underscore(decamelized);
      return (0, _emberInflector.pluralize)(underscored);
    }

  }

  var _default = ActiveModelAdapter;
  _exports.default = _default;
});