define("sitemap/templates/containers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4hSa33v9",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"container-index\",[],[[\"@model\",\"@moveContainer\",\"@addContainer\"],[[32,1],[32,0,[\"moveContainer\"]],[32,0,[\"addContainer\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],[\"newModal\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "sitemap/templates/containers/index.hbs"
    }
  });

  _exports.default = _default;
});