define("sitemap/templates/containers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sIa+rNXd",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"container-edit\",[],[[\"@model\",\"@publish\",\"@saveDraft\",\"@destroyModel\",\"@setDraftFromPayload\"],[[32,1],[32,0,[\"publish\"]],[32,0,[\"saveDraft\"]],[32,0,[\"destroyModel\"]],[32,0,[\"setDraftFromPayload\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sitemap/templates/containers/edit.hbs"
    }
  });

  _exports.default = _default;
});