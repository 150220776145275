define("sitemap/components/container/macros/x-resources", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Resources</legend>
    <Container::XFields @key="resources" @array={{true}} @size=3 @scope={{@scope}} as |resources|>
      <li class="stat-group">
        <Container::Macros::XResource @key="resourceId" @scope={{resources}} />
      </li>
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"gYxFhhsF","block":"{\"symbols\":[\"resources\",\"@scope\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Resources\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@size\",\"@scope\"],[\"resources\",true,\"3\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"li\"],[14,0,\"stat-group\"],[12],[2,\"\\n      \"],[8,\"container/macros/x-resource\",[],[[\"@key\",\"@scope\"],[\"resourceId\",[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-resources.hbs"}});

  class ContainerMacrosXResourcesComponent extends _component.default {}

  _exports.default = ContainerMacrosXResourcesComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXResourcesComponent);
});