define("sitemap/components/x-modal", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {"id":"LNxOOwGl","block":"{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/x-modal.hbs"}});

  class XModalComponent extends _component.default {}

  _exports.default = XModalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XModalComponent);
});