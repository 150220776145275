define("sitemap/adapters/contact", ["exports", "sitemap/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContactAdapter extends _application.default {
    pathForType() {
      return "contacts";
    }

  }

  _exports.default = ContactAdapter;
});