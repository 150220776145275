define("sitemap/lib/sitemap/tree", ["exports", "sitemap/lib/sitemap/node"], function (_exports, _node) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Tree = (_dec = Ember.inject.service, (_class = (_temp = class Tree extends Ember.Object {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "containerRequest", _descriptor, this);
    }

    init() {
      this.nodes = [];
      this.nodesByContainerId = {};
      this.containersById = {};
    }

    async load() {
      const containers = await this.containerRequest.get({
        root: true
      });
      containers.forEach(container => this.addContainer(container));
    }

    refresh() {
      this.nodes.clear();
      this.nodesByContainerId = {};
      this.load();
    }

    isDescendant(src, dest) {
      let parentIds = [];
      let parent = this.containerForId(dest.get('parentContainerId'));

      while (parent) {
        parentIds.push(parent.get('id'));
        parent = this.containerForId(parent.get('parentContainerId'));
      }

      if (parentIds.indexOf(src.get('id')) !== -1) {
        return true;
      }

      return false;
    }

    moveContainer(src, dest, pos) {
      let currentParentId = src.get('parentContainerId');
      let currentParent = this.nodeForContainerId(currentParentId);
      let currentId = src.get('id');
      let srcNode = this.nodeForContainerId(src.get('id'));
      let newParentId, newParent;

      if ('above' === pos || 'below' === pos) {
        newParentId = dest.get('parentContainerId');
      } else {
        newParentId = dest.get('id') || null;
      } // We need to make sure we're not trying to add the current page
      // to one of its children


      if (this.isDescendant(src, dest)) {
        return Ember.RSVP.resolve();
      } // can't drag on the same parent


      if (currentParentId === newParentId) {
        return Ember.RSVP.resolve();
      } // can't drag on itself


      if (currentId === newParentId) {
        return Ember.RSVP.resolve();
      }

      newParent = this.nodeForContainerId(newParentId);
      currentParent.nodes.removeObject(srcNode);

      if (0 === currentParent.nodes.length) {
        currentParent.hasDescendants = false;
      }

      return newParent.load().then(() => {
        newParent.hasDescendants = true;
        src.parentContainerId = newParentId;
        return this.containerRequest.saveModel(src, {
          without: ['data']
        }); //.then(() => this.refresh());
      }).then(container => {
        newParent.load();
        return container;
      }).catch(modelWithErrors => {
        if (modelWithErrors.errors && modelWithErrors.errors.length > 0) {
          currentParent.nodes.addObject(srcNode);
          newParent.nodes.removeObject(srcNode);
          this.refresh();
        }

        throw modelWithErrors;
      });
    }

    addContainer(container) {
      let id = container.id;
      let parentId = container.parentContainerId;
      let node = this.nodeForContainerId(id);
      let parentNode;
      node.model = container;
      node.tree = this;
      node.hasDescendants = container.childContainersCount > 0;
      this.containersById[id] = container;

      if (parentId) {
        parentNode = this.nodeForContainerId(parentId);
        parentNode.addChild(node);
      } else {
        this.nodes.addObject(node);
      }
    }

    containerForId(id) {
      return this.containersById[id];
    }

    nodeForContainerId(id) {
      let node;

      if (node = this.nodesByContainerId[id]) {
        return node;
      }

      node = _node.default.create(Ember.getOwner(this).ownerInjection(), {
        modelId: id
      });
      this.nodesByContainerId[id] = node;
      return node;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "containerRequest", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Tree;
});