define("sitemap/components/container/macros/x-widget-links-lists", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <fieldset>
    <legend>Links</legend>
    <Container::XFields @key="widgetLinksLists" @scope={{@scope}} @array={{true}} @max=2 as |widgetLinksList|>
      <Container::XField @key="title" @type="string" @limit=50 @scope={{widgetLinksList}} />
      <Container::XFields @key="links" @array={{true}} @max=5 @scope={{widgetLinksList}} as |links|>
        <li class="stat-group">
          <Container::XField @key="label" @type="string" @scope={{links}} />
          <Container::XField @key="url" @type="href" @scope={{links}} />
          <Container::XField @key="external" @type="boolean" @scope={{links}} />
        </li>
      </Container::XFields>
    </Container::XFields>
  </fieldset>
  
  */
  {"id":"kp/E4BXv","block":"{\"symbols\":[\"widgetLinksList\",\"links\",\"@scope\"],\"statements\":[[10,\"fieldset\"],[12],[2,\"\\n  \"],[10,\"legend\"],[12],[2,\"Links\"],[13],[2,\"\\n  \"],[8,\"container/x-fields\",[],[[\"@key\",\"@scope\",\"@array\",\"@max\"],[\"widgetLinksLists\",[32,3],true,\"2\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@limit\",\"@scope\"],[\"title\",\"string\",\"50\",[32,1]]],null],[2,\"\\n    \"],[8,\"container/x-fields\",[],[[\"@key\",\"@array\",\"@max\",\"@scope\"],[\"links\",true,\"5\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"stat-group\"],[12],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"label\",\"string\",[32,2]]],null],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"url\",\"href\",[32,2]]],null],[2,\"\\n        \"],[8,\"container/x-field\",[],[[\"@key\",\"@type\",\"@scope\"],[\"external\",\"boolean\",[32,2]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-widget-links-lists.hbs"}});

  class ContainerMacrosXWidgetLinksListComponent extends _component.default {}

  _exports.default = ContainerMacrosXWidgetLinksListComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXWidgetLinksListComponent);
});