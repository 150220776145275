define("sitemap/components/container/macros/x-show-section-nav", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h2>Show Section Nav?</h2>
  <Container::XField @key="showSectionNav" @label="Yes" @type="boolean" @default={{@default}} @scope={{@scope}} @dataTest="showSectionNav_checkbox" />
  
  */
  {"id":"n2ZuF8op","block":"{\"symbols\":[\"@default\",\"@scope\"],\"statements\":[[10,\"h2\"],[12],[2,\"Show Section Nav?\"],[13],[2,\"\\n\"],[8,\"container/x-field\",[],[[\"@key\",\"@label\",\"@type\",\"@default\",\"@scope\",\"@dataTest\"],[\"showSectionNav\",\"Yes\",\"boolean\",[32,1],[32,2],\"showSectionNav_checkbox\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sitemap/components/container/macros/x-show-section-nav.hbs"}});

  class ContainerMacrosXShowSectionNavComponent extends _component.default {}

  _exports.default = ContainerMacrosXShowSectionNavComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ContainerMacrosXShowSectionNavComponent);
});