define("sitemap/components/container/info/published-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>
    Last version Published by: <Container::Info::ShowContact @contactId={{@model.approvedById}} />
    <br>
    Approval status:
    <b>
      {{#if @model.hasDraft}}
        Pending
      {{else}}
        Approved
      {{/if}}
    </b>
    <br>
    Last version Edited by: <Container::Info::ShowContact @contactId={{@model.updatedById}} />
  </p>
  */
  {"id":"xL6hmgTe","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"p\"],[12],[2,\"\\n  Last version Published by: \"],[8,\"container/info/show-contact\",[],[[\"@contactId\"],[[32,1,[\"approvedById\"]]]],null],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  Approval status:\\n  \"],[10,\"b\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"hasDraft\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Pending\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Approved\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  Last version Edited by: \"],[8,\"container/info/show-contact\",[],[[\"@contactId\"],[[32,1,[\"updatedById\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"sitemap/components/container/info/published-by.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});