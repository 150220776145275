define("sitemap/routes/images/edit", ["exports", "sitemap/lib/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImagesEditRoute extends _authenticatedRoute.default {
    model(params) {
      return this.store.find('image', params.image_id);
    }

  }

  _exports.default = ImagesEditRoute;
});