define("sitemap/components/images/x-search", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form class="form-inline">
    <div class="form-group">
      <label for="query">Search:</label>
      <Input @value={{this.query}} class="form-control" @placeholder="search name" data-test="search_input" />
    </div>
    <button type="button" {{on "click" (fn this.search this.query)}} class="btn btn-default" data-test="search_button">Search</button>
  </form>
  
  */
  {"id":"mNOATFr0","block":"{\"symbols\":[],\"statements\":[[10,\"form\"],[14,0,\"form-inline\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"query\"],[12],[2,\"Search:\"],[13],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"data-test\",\"search_input\"]],[[\"@value\",\"@placeholder\"],[[32,0,[\"query\"]],\"search name\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-default\"],[24,\"data-test\",\"search_button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"search\"]],[32,0,[\"query\"]]],null]],null],[12],[2,\"Search\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}","meta":{"moduleName":"sitemap/components/images/x-search.hbs"}});

  let ImagesXSearchComponent = (_dec = Ember._action, (_class = (_temp = class ImagesXSearchComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isDisabled", false);
    }

    search(query) {
      this.args.search(query);
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "search", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
  _exports.default = ImagesXSearchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ImagesXSearchComponent);
});