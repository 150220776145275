define("sitemap/components/x-label", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="sitemap-x-label {{if this.isDraggable "is-draggable"}}"
       draggable={{this.isDraggable}}
       droppable="true"
       {{on "dragstart" this.startedDragging}}
       {{on "dragover" this.mightDropNode}}
       {{on "dragleave" this.wontDropNode}}
       {{on "dragend" this.stoppedDragging}}
       {{on "drop" this.didDropNode}}>
    {{yield}}
  </div>
  */
  {"id":"WKI/Mzyx","block":"{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"sitemap-x-label \",[30,[36,0],[[32,0,[\"isDraggable\"]],\"is-draggable\"],null]]]],[16,\"draggable\",[32,0,[\"isDraggable\"]]],[24,\"droppable\",\"true\"],[4,[38,1],[\"dragstart\",[32,0,[\"startedDragging\"]]],null],[4,[38,1],[\"dragover\",[32,0,[\"mightDropNode\"]]],null],[4,[38,1],[\"dragleave\",[32,0,[\"wontDropNode\"]]],null],[4,[38,1],[\"dragend\",[32,0,[\"stoppedDragging\"]]],null],[4,[38,1],[\"drop\",[32,0,[\"didDropNode\"]]],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}","meta":{"moduleName":"sitemap/components/x-label.hbs"}});

  const DROP_OUTSIDE_THRESHOLD = 0.28;
  const ABOVE = 'above';
  const BELOW = 'below';
  const INSIDE = 'inside';
  let XLabelComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.computed.oneWay('args.node.model.draggable'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class XLabelComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "mightDropPosition", _descriptor, this);

      _initializerDefineProperty(this, "isBeingDragged", _descriptor2, this);

      _defineProperty(this, "isDroppable", true);

      _initializerDefineProperty(this, "isDraggable", _descriptor3, this);
    }

    mightDropNode(event) {
      let y = event.layerY;
      let h = event.currentTarget.scrollHeight;
      let top = event.currentTarget.offsetTop - document.querySelector('body').scrollTop;
      let bot = top + h;
      let insRng = h * DROP_OUTSIDE_THRESHOLD;
      let pxFromTop = y - top;
      let pxFromBot = bot - y;
      let position;
      event.preventDefault();

      if (pxFromTop <= insRng) {
        position = ABOVE;
      } else if (pxFromBot <= insRng) {
        position = BELOW;
      } else {
        position = INSIDE;
      }

      this.mightDropPosition = position;
      this.args.onDragOver(position);
    }

    wontDropNode() {
      this.args.onDragLeave();
      this.mightDropPosition = null;
    }

    didDropNode(event) {
      let srcId = event.dataTransfer.getData('text/x-container-id');
      this.args.onDrop({
        sourceId: parseInt(srcId, 10),
        destId: this.args.node.modelId,
        position: this.mightDropPosition
      });
      this.mightDropPosition = null;
    }

    startedDragging(event) {
      let id = this.args.node.modelId;
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/x-container-id', id);
      this.isBeingDragged = true;
    }

    stoppedDragging() {
      this.isBeingDragged = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mightDropPosition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isBeingDragged", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDraggable", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "mightDropNode", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "mightDropNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wontDropNode", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "wontDropNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didDropNode", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "didDropNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startedDragging", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "startedDragging"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stoppedDragging", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "stoppedDragging"), _class.prototype)), _class));
  _exports.default = XLabelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XLabelComponent);
});